.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.modal-title  {
  font-size: 16px;
}

.App-link {
  color: #61dafb;
}
#countdowntimer { font-size: 16px;}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
canvas { height: 200px !important; }
.nh canvas { height: 224px !important;}
.info-box .info-box-icon {
  font-size: 20px !important;
}
.info-box-number {
  font-size: 22px !important;
}
.daily { line-height: 14px ;}
.daily span{ font-size: 12px !important;}
.text-dark { padding-left: 10px; font-size: 24px;}
.err-msg {
  color: red; font-size: 12px; font-weight: bold;
}
.dynamicForm td {
  line-height: 12px;
}
.custom-data-table .rdt_TableHeadRow {
  text-align: center;
}
.full-screen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050; /* Ensure it's above other content, like the modal */
}
.custom-data-table .rdt_TableCol {
  justify-content: center;
  text-align: center;
}

.react-datepicker-wrapper { display: block !important;}
.days {
  padding: 0;
  margin: 0;
  list-style: none;
}
.days li {
  margin-right: 10px;
  margin-bottom: 10px;
  float: left;
}
.days .today-bg a {
  background: rgb(230, 122, 21) !important;
  cursor: pointer !important;
  color: #fff !important;
}
.days li a {
  background-color: #f1f1f1;
  border-radius: 53px;
  display: block;
  width: 50px;
  text-align: center;
  height: 50px;
  padding-top: 15px;
  color: #000;
  cursor: default;
}
/* .days li a:focus {
  background-color: #e0a800 !important; border: 0;
} */
.days { border: 0; text-decoration: none;}
.days .active a{
  background-color: #e0a800 !important; border: 0; color: #fff;
}
.days .green-bg a {
  background: green !important;
  color: #fff !important;
  cursor: pointer !important;
}
.spacer { margin-top: 30px;}
.rdt_TableHeader { display: none !important;}
.controls {  padding: 0 0 10px 0; list-style: none; float: right;
 width: 100%; text-align: right;}
.controls li {  margin-left: 10px; display: inline-block;}
.weightList { list-style: none; padding:0; margin: 0;}
.labelText { font-size: 14px; text-align: right; margin-top: 5px;}
.refresh { cursor: pointer; font-size: 12px;}
.refresh:hover { text-decoration: underline;  }

.custom-alert { padding: 5px 10px; font-size: 12px; margin-bottom: 5px; font-weight: bold;}
.alert .close { position: absolute; right:5px; top:5px; color: #fff !important; font-size: 14px;}
.login-box, .register-box  { margin: auto; }
.font12 { font-size: 14px;}
.mt10 { margin-top: 30px;}

.styles_Editext__button__6H8n_ {
  padding: 2px !important;
}

.styles_Editext__input__1534X {
  padding: 2px 10px !important;
}
.dailyRow {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 5px 0 10px 0;
}
.borderless td, .borderless th {
  border: none;
}
.blueColor { color: #007bff; font-weight: bold; text-transform: uppercase;}

.summary-table th{ background: #eee; color: #000; }
/* .summary-table td { background: #eee; } */
#summary .inner h3 { font-size: 24px;}
.tabs { width: 100%; }
.tab-content { padding-top: 15px; }
.nav-item { cursor: pointer;}

.greenbg { background-color: #218838; color: #fff; padding: 3px 5px; border-radius: 5px;}
.redbg { background-color: #c82333; color: #fff; padding: 3px 5px; border-radius: 5px;}
.view-btn { color: #007bff; cursor: pointer; }
.tab-head1 {
  background-color: #777; color: #fff;
}
.tab-head2   {
  background-color: #f1f1f1;  color: #000;
}
.phases th, td {
  vertical-align: middle; padding: 5px; font-size: 14px;
}
.text-size { font-size: 20px !important;}
.padding-align { padding:0  15px; }
.batchStatus { font-size: 14px; color: #c82333;}
.expensesList { padding:0 ; margin: 0;  }
.expensesList  li { list-style: none; }
.spanFont { font-weight: bold; font-size: 12px;}

.totalBox {  color: #007bff; font-weight: bold;}
.totalBox .number { color: #282c34; }
.displaynone {display: none; }
.bluebg { background: #007bff; color: #fff; padding: 3px 5px; border-radius: 5px; }
.workers th, .workers td { padding: 5px; }
.select2-container .select2-selection--single { height:38px !important;}
.select2-container--default .select2-selection--single .select2-selection__arrow { top: 6px; }
.select2-container .select2-selection--single .select2-selection__rendered { padding-left: 0;}
.info-box2 { width: 30px;}
.icon-holder {
 
  /* background: #555; border-radius: 35px; */
  float: left;
  margin-bottom: 20px;
  width: 40px;
}
.text-holder {
  float: left; padding-left: 10px; margin-top:10px;
}
.icon-holder i { color: #fff; width: 100%;}
.icon-holder .f-18 { font-size: 18px;}
.color-red, .red { color: #c82333;}

.green { color: #03863b}
.orange{ color: rgb(255, 162, 0);}
.red { color: #c82333; }
.loadingPage {
  background: rgba(0,0,0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10000;
}
.gray { color: #666;}
.signal { font-size: 14px; display: inline-block; margin-top: 5px; font-weight: bold;}
.buttonsUL { margin: 0; padding: 0; }
.buttonsUL li { list-style: none; float: left; margin-right: 10px;}
.myappcontent {
  padding:20px; min-height: 460px;
}
/* .notifications-dropdown {
  max-width: 100%;
} */
.widget-sm {
  background: '#fff';
  padding: 15px;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #ccc;
  min-height: 150;
}
h6{
  color: '#0085FF !important'
}
.bigNumber span{
  font-size: 40px;
  display: block;
}
.bigNumber {
  text-align: right;
}
.viewDetails {
  font-size: 12px; text-transform: uppercase;
  font-weight: bold;
  color: #555; 
  cursor: pointer;
}
.viewDetails :hover { text-decoration: underline; color: #0079FF !important;}
.chartArea {
  margin-top: 10px;
}
.clear { clear: both; font-size: 0;}
.card-header i { color: #187800 }
.main-header { box-shadow: 1px 1px 5px #ccc; padding: 0;}
.echarts-for-react  { top: 0 }
.main-footer { text-align: center; padding: 10px; background: '#EEE';}
.content { background: #f1f1f1;}
.stepwizard-step p {
  margin-top: 10px;
}
.stepwizard-row {
  display: table-row;
}
.stepwizard {
  display: table;
  width: 50%;
  position: relative;
}
.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}
.stepwizard-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #ccc;
  z-order: 0;
}
.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}
.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}
.spacer { margin-top: 20;}
.text-success { color: #218838; font-weight: bold;}
.text-danger { color: #c82333; font-weight: bold;}
h3 { font-size: 16px !important; text-transform: uppercase; font-weight:500;}
h4 {  font-size: 16px !important;}
.entry {
  padding: 2px; background-color:#218838; cursor: pointer; color: #fff;
  text-align: center; display: inline-block; width: 100px; border-radius: 5px;
}
.smallFont { font-size: 11px; color: #555;}
.alignDashboardLoader { margin-top: 0; padding-top: 0;}
.dashbioardLoderContainer { position: relative;}
.dashboardLoader {     
  position: absolute;
  top: -30px;
  right: 140px; 
} 
.dayReadings {
  float: right;
  list-style: none;
  padding: 3px 0 0 0; margin: 0;
}
.dayReadings li {
  display: inline-block;
  padding-left: 10px;
  font-size: 14px;
  padding-right: 10px;
  border-right: 1px solid #ccc;
  color: #0ca803;
  text-transform: uppercase;
}
.dayReadings span { color: #333; font-weight: bold;}
.dayReadings li:last-child { border: 0}
.card-footer { font-size: 14px; font-style: italic; color: #666;
border-top: 1px solid #ccc}

.active { font-weight: 600;}
.navbar-nav li { text-transform: uppercase; font-size: 14px; font-weight: 600;}
.navbar-nav { padding-top: 4px;}
.card-body { min-height: 200px;}
.main-header {
  border-top: 5px solid #187800;
}
.nav-link.active { color: #187800;}

.tablist .nav-item { border:0 !important;}
.tablist .active { font-weight: 100 !important;}

.tablist .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  border:0; font-weight: normal;
  background: none !important;
  border-bottom: 2px solid #187800;
}
.current { font-weight: bold; font-size: 30px;}

.readings_summary { list-style: none; margin: 0; padding: 0 ;}
.readings_summary li { margin-bottom: 10px;}

.col-bg {
  background: #fff;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
} 
h5 {
  font-size: 14px; text-transform: uppercase;
  border-bottom: 1px solid #ccc; margin-bottom: 15px; padding-bottom: 15px;
}
.headerTitle {
  list-style: none;
  margin: 0; padding: 0;
}
.headerTitle li { display: inline-block; margin-right: 20px;}

.brand-text, brand-text:hover, brand-text:visited {
  color: #000; font-weight: bold;
  font-size: 20px; margin-top: 10px;
}
.dropdown-menu .nav-link { font-size: 14px;}
.refresh { 
  text-align: right; 
  text-transform: uppercase;
  font-size: 16px; cursor: pointer;
  color: #03863b;
}
.rdt_TableCol_Sortable { text-transform: uppercase; 
font-weight: bold; font-size: 12px;
}

.container-fluid { padding: 0 70px;}


.custom-tabs .nav-link {
  background-color: transparent !important;
  border-color: transparent !important; 
}

.custom-tabs .nav-link.active {
  background-color: #f8f9fa !important; 
 
 border-bottom: 2px solid #187800 !important;
}
.custom-tabs .nav-link:focus {
  outline: none !important;
  box-shadow: none !important;

}
.totalDevices {
  position: absolute;
  right: 25px
}
.textHighlighter {
  background-color: #03863b;
  color: #fff;
  border-radius: 10px;
  text-align: center;
  display: inline-block;
  padding: 5px 10px;
}